import React, { useState } from 'react'
import { graphql } from 'gatsby'

import { NavBar, Hero, Perks, Data, Offer, Footer, SEO } from '@components'
import { homePageSelector } from '@data'
import { getPrismicText } from '@utils'

import Carousel, { Dots } from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

import '@styles/index.scss'

const HomePage = (props) => {
  const { data: prismic = {} } = { ...props }

  const data = homePageSelector(prismic)
  const {
    perks = {},
    perksSectionItems = [],
    carouselStatisticsSectionItems = [],
    offer = {},
    offerSectionItems = [],
    carouselTestimonialsSectionItems = [],
  } = { ...data }
  const { title: perksSectionTitle = '' } = { ...perks }
  const {
    image: offerSectionImage = '',
    title: offerSectionTitle = '',
    description: offerSectionDescription,
  } = { ...offer }

  const imagesThumb = carouselTestimonialsSectionItems.map(
    (carouselItem, index) => {
      const {
        carousel_testimonials_section_image,
        carousel_testimonials_section_name,
      } = { ...carouselItem }
      return (
        <div className={'img-thumb'}>
          <img
            src={carousel_testimonials_section_image.url}
            alt={getPrismicText(carousel_testimonials_section_name)}
          />
        </div>
      )
    }
  )

  const [value, setValue] = useState(0)
  const onchange = (value) => setValue(value)

  return (
    <main className={'home-section'}>
      <SEO title={'Home'} />
      <NavBar data={data} />
      <Hero section={'home'} data={data} />
      <section className={'partners'}>
        <div className={'partners__container'}>
          <h2
            className={'partners__container-title'}
            data-sal={'fade'}
            data-sal-delay={'50'}
            data-sal-easing={'ease'}
            data-sal-duration={'800'}
          >
            {perksSectionTitle}
          </h2>
          <section className={'companies-providers'}>
            {(perksSectionItems || []).map((perksSectionItem, index) => {
              return (
                <Perks
                  key={index}
                  perksSectionItem={perksSectionItem}
                  index={index}
                  showButton
                />
              )
            })}
          </section>
        </div>
      </section>
      <section className={'big-blue'}>
        <section className={'look-mobile'}>
          <Data data={data} section={'home'} />
        </section>
        <section className={'carousel-bg'}>
          <div className={'carousel'}>
            <Carousel autoPlay={7000} infinite arrows dots>
              {(carouselStatisticsSectionItems || []).map(
                (carouselItem, index) => {
                  const {
                    carousel_statistics_section_title,
                    carousel_statistics_section_paragraph,
                    carousel_statistics_section_description,
                    carousel_statistics_section_value,
                  } = { ...carouselItem }
                  return (
                    <div className={'carousel-container'} key={index}>
                      <div className={'carousel-container__left'}>
                        <h2>
                          {getPrismicText(carousel_statistics_section_title)}
                        </h2>
                        <p>
                          {getPrismicText(
                            carousel_statistics_section_paragraph
                          )}
                        </p>
                      </div>
                      <div className={'carousel-container__right'}>
                        <p className={'carousel-container__right-percentage'}>
                          {getPrismicText(carousel_statistics_section_value)}
                        </p>
                        <p>
                          {getPrismicText(
                            carousel_statistics_section_description
                          )}
                        </p>
                      </div>
                    </div>
                  )
                }
              )}
            </Carousel>
          </div>
        </section>
        <section className={'features'}>
          <div className={'features__container'}>
            <div className={'content-left'}>
              <div className={'content-left__description'}>
                <h2
                  className={'content-left__description-title'}
                  data-sal={'fade'}
                  data-sal-delay={'50'}
                  data-sal-easing={'ease'}
                  data-sal-duration={'800'}
                >
                  {offerSectionTitle}
                </h2>
                <p
                  className={'content-left__description-paragraph'}
                  data-sal={'fade'}
                  data-sal-delay={'100'}
                  data-sal-easing={'ease'}
                  data-sal-duration={'800'}
                >
                  {offerSectionDescription}
                </p>
              </div>
              <div className={'feats'}>
                {(offerSectionItems || []).map((offerSectionItem, index) => {
                  return (
                    <Offer key={index} offerSectionItem={offerSectionItem} />
                  )
                })}
              </div>
            </div>
            <div className={'content-right'}>
              <img src={offerSectionImage} alt={'lugar de ejercicio'} />
            </div>
          </div>
        </section>
        <section className={'testimonials-carousel-bg'}>
          <div className={'testimonials'}>
            <Carousel
              autoPlay={10000}
              infinite
              onChange={onchange}
              value={value}
            >
              {(carouselTestimonialsSectionItems || []).map(
                (carouselItem, index) => {
                  const {
                    carousel_testimonials_section_image,
                    carousel_testimonials_section_name,
                    carousel_testimonials_section_job,
                    carousel_testimonials_section_specialty,
                    carousel_testimonials_section_testimony,
                  } = { ...carouselItem }
                  return (
                    <div className={'testimonials-container'} key={index}>
                      <div className={'testimonials-container__left'}>
                        <div className={'testimonials-container__left-image'}>
                          <img
                            src={carousel_testimonials_section_image.url}
                            alt={getPrismicText(
                              carousel_testimonials_section_name
                            )}
                          />
                        </div>
                        <p className={'testimonials-container__left-name'}>
                          {getPrismicText(carousel_testimonials_section_name)}
                        </p>
                        <p className={'testimonials-container__left-job'}>
                          {getPrismicText(carousel_testimonials_section_job)}
                        </p>
                      </div>
                      <div className={'testimonials-container__right'}>
                        <h2>
                          {getPrismicText(
                            carousel_testimonials_section_specialty
                          )}
                        </h2>
                        <p>
                          {getPrismicText(
                            carousel_testimonials_section_testimony
                          )}
                        </p>
                      </div>
                    </div>
                  )
                }
              )}
            </Carousel>
            <Dots thumbnails={imagesThumb} onChange={onchange} value={value} />
          </div>
        </section>
      </section>
      <Footer data={data} />
    </main>
  )
}

export const query = graphql`
  query HomePageQuery($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    prismicMainPage(lang: { eq: $lang }) {
      data {
        body {
          primary {
            navbar_section_logo {
              url
            }
            navbar_section_logo_mobile {
              url
            }
            navbar_section_cta_label_home {
              text
            }
            navbar_section_cta_link_home {
              text
            }
            navbar_section_cta_label_providers {
              text
            }
            navbar_section_cta_link_providers {
              text
            }
            navbar_section_cta_label_companies {
              text
            }
            navbar_section_cta_link_companies {
              text
            }
            navbar_section_cta_label_demo {
              text
            }
            navbar_section_cta_link_demo {
              url
            }
            navbar_section_cta_label_login {
              text
            }
            navbar_section_cta_link_login {
              url
            }
            navbar_section_cta_label_switcher {
              text
            }
            navbar_section_cta_link_switcher {
              text
            }
            hero_title {
              text
            }
            hero_paragraph {
              text
            }
            hero_image {
              url
            }
            hero_cta_label {
              text
            }
            hero_cta_link {
              url
            }
            perks_section_title {
              text
            }
            data_section_title {
              text
            }
            data_section_paragraph {
              text
            }
            data_section_cta_label {
              text
            }
            data_section_cta_link {
              url
            }
            data_section_image {
              url
            }
            offer_section_title {
              text
            }
            offer_section_paragraph {
              text
            }
            offer_section_image {
              url
            }
            footer_section_logo {
              url
            }
            footer_section_cta_label_about {
              text
            }
            footer_section_cta_label_with_us {
              text
            }
            footer_section_cta_label_press {
              text
            }
            footer_section_cta_link_press {
              text
            }
            footer_section_cta_label_contact {
              text
            }
            footer_section_logo_facebook {
              url
            }
            footer_section_cta_link_facebook {
              url
            }
            footer_section_logo_instagram {
              url
            }
            footer_section_cta_link_instagram {
              url
            }
            footer_section_logo_linkedin {
              url
            }
            footer_section_cta_link_linkedin {
              url
            }
            footer_section_copyright {
              text
            }
            footer_section_cta_label_privacy {
              text
            }
            footer_section_cta_label_terms {
              text
            }
          }
          items {
            perk_image {
              url
            }
            perk_title {
              text
            }
            perk_paragraph {
              text
            }
            perk_cta_label {
              text
            }
            perk_cta_link {
              text
            }
            carousel_statistics_section_title {
              text
            }
            carousel_statistics_section_paragraph {
              text
            }
            carousel_statistics_section_description {
              text
            }
            carousel_statistics_section_value {
              text
            }
            offer_image {
              url
            }
            offer_title {
              text
            }
            offer_paragraph {
              text
            }
            carousel_testimonials_section_image {
              url
            }
            carousel_testimonials_section_name {
              text
            }
            carousel_testimonials_section_job {
              text
            }
            carousel_testimonials_section_specialty {
              text
            }
            carousel_testimonials_section_testimony {
              text
            }
          }
        }
      }
    }
  }
`
export default HomePage
